<template>
    <div ref="hoverElement" class="popover-personinfo bs-popover-auto fade shadow overflow-hidden" style="width: fit-content;" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2" >
            <div class="fw-bold d-flex">
                <div style="font-size: smaller; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    {{ viewProfileInfo?.Name }}
                </div>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{ $t('Loading...') }}
        </h3>
        <div class="popover-body p-0 overflow-hidden text-center" style="background-color: #f0f0f0;">
            <template v-if="isLoaded">
                <img :src="`/api/data/file/aviw_BIM_ViewProfiles/${viewProfileInfo?.PrimKey}`" style="height: 100px;"/>
            </template>
            <template v-else>
                {{ $t('Loading...') }} 
            </template>
        </div>
    </div>
</template>

<script lang="ts">
const ViewProfileCache = new Map<number, IViewProfileInfo>();

export interface IViewProfileInfo {
    ID: number;
    Name: string;
    FileRef: string;
    PrimKey: string;
};

export interface IViewProfileHoverProps {
    viewProfileId?: number;
}
</script>

<script setup lang="ts">
import API from 'o365.modules.data.api.ts';
import { ref, watch, onMounted } from 'vue';
import $t from 'o365.modules.translate.ts';
import logger from 'o365.modules.Logger.ts';

const props = defineProps<IViewProfileHoverProps>();

const showContent = ref(false);
const isLoaded = ref(false);
const viewProfileInfo = ref<IViewProfileInfo | null>(null);
const hoverElement = ref<HTMLElement | null>(null);

function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && viewProfileInfo.value?.ID != props.viewProfileId) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({ hoverElement, hoverHide, hoverShow });

async function loadViewProfileData(viewProfileId: number) {
    const result = await API.request({
        requestInfo: '/nt/api/data/aviw_BIM_ViewProfiles',
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            "viewName": "aviw_BIM_ViewProfiles",
            "distinctRows": false,
            "skip": 0,
            "fields": [
                { name: "ID", type: "number" },
                { name: "Name", type: "string" },
                { name: "FileRef", type: "string" },
            ],
            "maxRecords": 1,
            "whereClause": `ID = ${viewProfileId}`,
            "masterDetailString": null,
            "filterString": null,
            "operation": "retrieve"
        })
    });
    return result != null ? result[0] : result;
}

async function handleShow() {
    isLoaded.value = false;
    viewProfileInfo.value = null;
    if (props.viewProfileId == null) { return; }
    let viewProfileData = ViewProfileCache.get(props.viewProfileId);
    if (viewProfileData == null) {
        try {
            viewProfileData = await loadViewProfileData(props.viewProfileId);
            if (viewProfileData == null) { return; }
            ViewProfileCache.set(props.viewProfileId, viewProfileData);
        } catch (ex) {
            logger.error(ex);
            return;
        }
    }
    viewProfileInfo.value = viewProfileData;
    isLoaded.value = true;
}

watch(() => props.viewProfileId, (_newWorkflowId) => {
    handleShow();
});

onMounted(() => {
    handleShow();
});

</script>
